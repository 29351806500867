import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { formatColumnName } from './dataProcessing';
import SortingControls from './SortingControls';
import ColumnManager from './ColumnManager';

const ControlPanel = ({ 
  selectedXAxis, setSelectedXAxis, 
  selectedYAxis, setSelectedYAxis,
  sortColumn, setSortColumn, 
  sortOrder, setSortOrder, 
  columns,
  schema,
  isVisible,
  viewMode,
  visibleColumns,
  setVisibleColumns,
  columnOrder,
  setColumnOrder,
  showSummary,
  setShowSummary 
}) => {
  const [height, setHeight] = useState('auto');
  const contentRef = React.useRef(null);
  const [isYAxisOpen, setIsYAxisOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (isVisible && contentRef.current) {
        const scrollHeight = contentRef.current.scrollHeight;
        setHeight(scrollHeight);
      } else {
        setHeight(0);
      }
    };

    updateHeight();
    const timeoutId = setTimeout(updateHeight, 100);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsYAxisOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, viewMode, columns]);

  if (columns.length < 2) {
    return null;
  }

  const getNumericColumns = () => {
    return columns.filter(column => {
      const field = schema?.fields?.find(f => f.name === column);
      return field && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(field.type.toUpperCase());
    });
  };

  const handleYAxisCheck = (column, isChecked) => {
    const currentSelection = Array.isArray(selectedYAxis) ? selectedYAxis : [selectedYAxis];
    
    let newSelection;
    if (isChecked) {
      // Adding a new axis
      newSelection = [...currentSelection, column];
    } else {
      // Removing an axis
      newSelection = currentSelection.filter(axis => axis !== column);
      
      // If all axes are deselected, find a default axis
      if (newSelection.length === 0) {
        const numericColumns = getNumericColumns();
        // Find the first available numeric column that's not the X axis
        const defaultAxis = numericColumns.find(col => col !== selectedXAxis);
        if (defaultAxis) {
          newSelection = [defaultAxis];
        }
      }
    }
    
    setSelectedYAxis(newSelection);
  };

  return (
    <div className="relative">
      <div 
        className="transition-all duration-300 ease-in-out"
        style={{ maxHeight: height === 0 ? '0px' : (height === 'auto' ? 'none' : `${height}px`) }}
      >
        <div ref={contentRef} className="w-full mt-2 bg-gray-50 p-2 rounded-md">
          {viewMode === 'table' ? (
            <div className="flex justify-between items-center">
              <div className="w-full max-w-md">
                <ColumnManager
                  columns={columns}
                  visibleColumns={visibleColumns}
                  setVisibleColumns={setVisibleColumns}
                  columnOrder={columnOrder}
                  setColumnOrder={setColumnOrder}
                  schema={schema}
                />
              </div>
              <button
                onClick={() => setShowSummary(!showSummary)}
                className="flex items-center space-x-1 text-sm text-gray-700 hover:text-gray-900 ml-4"
              >
                <span>Summary Statistics</span>
                {showSummary ? 
                  <ChevronUp className="w-4 h-4" /> : 
                  <ChevronDown className="w-4 h-4" />
                }
              </button>
            </div>
          ) : (
            <div className="flex flex-wrap gap-4 justify-end">
              <div className="flex flex-col space-y-2 min-w-[120px]">
                <label className="block text-sm font-medium text-gray-700">X-Axis</label>
                <select
                  value={selectedXAxis}
                  onChange={(e) => setSelectedXAxis(e.target.value)}
                  className="w-full text-sm py-1 px-2 border rounded-md bg-white"
                >
                  {columns.map(column => (
                    <option key={column} value={column}>{formatColumnName(column)}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col space-y-2 min-w-[200px]">
                <label className="block text-sm font-medium text-gray-700">Y-Axis</label>
                <div 
                  ref={dropdownRef}
                  className="relative"
                  style={{ zIndex: 40 }}
                >
                  <div
                    onClick={() => setIsYAxisOpen(!isYAxisOpen)}
                    className="w-full text-sm py-1 px-2 border rounded-md bg-white cursor-pointer flex justify-between items-center"
                  >
                    <span className="truncate">
                      {Array.isArray(selectedYAxis) && selectedYAxis.length > 0
                        ? selectedYAxis.length === 1
                          ? formatColumnName(selectedYAxis[0])
                          : 'Multiple'
                        : 'Select Y-Axis'}
                    </span>
                    <ChevronDown className={`w-4 h-4 transition-transform ${isYAxisOpen ? 'transform rotate-180' : ''}`} />
                  </div>
                  {isYAxisOpen && (
                    <div className="absolute z-30 mt-1 w-full bg-white border rounded-md shadow-lg">
                      <div className="max-h-40 overflow-y-auto">
                        {getNumericColumns().map(column => (
                          <div key={column} className="flex items-center space-x-2 py-1 px-2 hover:bg-gray-50">
                            <input
                              type="checkbox"
                              id={`y-axis-${column}`}
                              checked={Array.isArray(selectedYAxis) 
                                ? selectedYAxis.includes(column) 
                                : selectedYAxis === column}
                              onChange={(e) => handleYAxisCheck(column, e.target.checked)}
                              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <label htmlFor={`y-axis-${column}`} className="text-sm text-gray-700 cursor-pointer">
                              {formatColumnName(column)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <SortingControls
                  sortColumn={sortColumn}
                  setSortColumn={setSortColumn}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  columns={columns}
                  schema={schema}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;