import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const UserProfileModal = ({ isOpen, onClose, userEmail }) => {
  const { saveUserPreferences } = useAuth();
  const [formData, setFormData] = useState({
    hebrewName: '',
    englishName: '',
    role: '',
    preferences: '',
    enablePersonalization: true
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [charCount, setCharCount] = useState(0);
  const modalRef = useRef();

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const userPrefsRef = doc(db, 'registeredUsersPreferences', userEmail);
        const docSnap = await getDoc(userPrefsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData({
            hebrewName: data.hebrewName || '',
            englishName: data.englishName || '',
            role: data.role || '',
            preferences: data.preferences || '',
            enablePersonalization: data.hasOwnProperty('enablePersonalization') ? data.enablePersonalization : true
          });
          setCharCount(data.preferences?.length || 0);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    if (isOpen && userEmail) {
      fetchUserPreferences();
    }
  }, [isOpen, userEmail]);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.hebrewName.trim()) {
      newErrors.hebrewName = 'Hebrew name is required';
    }
    if (!formData.englishName.trim()) {
      newErrors.englishName = 'English name is required';
    }
    
    const sanitizedRole = formData.role.trim();
    if (!sanitizedRole) {
      newErrors.role = 'Role is required';
    } else if (sanitizedRole.length < 3) {
      newErrors.role = 'Role must be at least 3 characters long';
    } else if (!/^[\p{L}\s"״״]+$/u.test(sanitizedRole)) {
      newErrors.role = 'Role can only contain letters, spaces, and quotation marks';
    }

    if (formData.preferences && formData.preferences.length > 300) {
      newErrors.preferences = 'Preferences must not exceed 300 characters';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePreferencesChange = (e) => {
    const text = e.target.value;
    if (text.length <= 300) {
      setFormData(prev => ({ ...prev, preferences: text }));
      setCharCount(text.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    
    if (validateForm()) {
      setIsLoading(true);
      try {
        const userPrefsRef = doc(db, 'registeredUsersPreferences', userEmail);
        
        const docSnap = await getDoc(userPrefsRef);
        const currentData = docSnap.exists() ? docSnap.data() : {};

        const updateData = {
          ...currentData,
          hebrewName: formData.hebrewName.trim(),
          englishName: formData.englishName.trim(),
          role: formData.role.trim(),
          preferences: formData.preferences?.trim() || '',
          enablePersonalization: formData.enablePersonalization,
          lastUpdated: new Date()
        };

        await setDoc(userPrefsRef, updateData, { merge: true });
        await saveUserPreferences(userEmail, updateData);
        
        setSuccessMessage('Profile updated successfully!');
        setTimeout(() => {
          setSuccessMessage('');
          onClose();
        }, 2000);
      } catch (error) {
        console.error('Error saving preferences:', error);
        setErrors({ submit: 'Failed to update profile. Please try again.' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!isOpen) return null;
  
  const inputClassName = (fieldError) => `
    w-full p-2 border rounded-md 
    ${fieldError ? 'border-red-500' : 'border-gray-300'}
    ${!formData.enablePersonalization ? 'opacity-60 bg-gray-50' : ''}
  `;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={modalRef} className="bg-white rounded-lg shadow-xl w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="h-5 w-5" />
        </button>

        <div className="bg-gray-100 px-6 py-4 rounded-t-lg">
          <h2 className="text-xl font-semibold text-gray-800">Profile Settings</h2>
          <p className="text-sm text-gray-600 mt-1">
            Update your personal information
          </p>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            {/* Personalization Toggle moved to top */}
            <div className="flex items-center justify-between mb-6 pb-4 border-b">
              <div>
                <label className="text-sm font-medium text-gray-700">
                  Enable Personalization
                </label>
                <p className="text-xs text-gray-500 mt-1">
                  Allow Tango to personalize the analysis
                </p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.enablePersonalization}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    enablePersonalization: e.target.checked 
                  }))}
                  className="sr-only peer"
                />
                <div className={`w-11 h-6 bg-gray-200 rounded-full peer 
                  peer-checked:after:translate-x-full peer-checked:after:border-white 
                  after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                  after:bg-white after:border-gray-300 after:border after:rounded-full 
                  after:h-5 after:w-5 after:transition-all ${
                    formData.enablePersonalization ? 'bg-secondary' : ''
                  }`}>
                </div>
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name (Hebrew)
              </label>
              <input
                type="text"
                value={formData.hebrewName}
                onChange={(e) => setFormData(prev => ({ ...prev, hebrewName: e.target.value }))}
                className={inputClassName(errors.hebrewName)}
                dir="rtl"
              />
              {errors.hebrewName && (
                <p className="text-red-500 text-xs mt-1">{errors.hebrewName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name (English)
              </label>
              <input
                type="text"
                value={formData.englishName}
                onChange={(e) => setFormData(prev => ({ ...prev, englishName: e.target.value }))}
                className={inputClassName(errors.englishName)}
              />
              {errors.englishName && (
                <p className="text-red-500 text-xs mt-1">{errors.englishName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Role
              </label>
              <input
                type="text"
                value={formData.role}
                onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
                placeholder="Enter your role"
                className={inputClassName(errors.role)}
              />
              {errors.role && (
                <p className="text-red-500 text-xs mt-1">{errors.role}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Preferences
              </label>
              <textarea
                value={formData.preferences}
                onChange={handlePreferencesChange}
                placeholder="Enter your preferences (optional)"
                className={`${inputClassName(errors.preferences)} h-24 resize-none`}
              />
              <div className="flex justify-between text-xs text-gray-500 mt-1">
                <span>{charCount}/300 characters</span>
                {errors.preferences && (
                  <span className="text-red-500">{errors.preferences}</span>
                )}
              </div>
            </div>
          </div>

          {errors.submit && (
            <p className="text-red-500 text-sm mt-4">{errors.submit}</p>
          )}

          {successMessage && (
            <p className="text-green-500 text-sm mt-4">{successMessage}</p>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfileModal;